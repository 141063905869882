import { Button, Grid } from "@mui/material"
import usePermission from "hooks/usePermission"
import useTopPanel from "hooks/useTopPanel"
import React, { useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

const Settings = () => {
	const { setComponent } = useTopPanel()
	const { hasPermission } = usePermission()
	const { t, i18n } = useTranslation()

	useEffect(() => {
		setComponent(<div className="component-title">{t("settings.title")}</div>)
	}, [i18n.language])

	return (
		<div className="mt-6 setting-wrapper">
			<Grid
				container
				spacing={{ xs: 2, sm: 2, lg: 2 }}
				columns={{ xs: 12, sm: 12, lg: 12 }}
			>
				{hasPermission("SETTINGS_COMPANY") && (
					<Grid item={true} lg={4} sm={6} xs={12}>
						<Link to="/admin/settings/company" className="settings-item">
							<Button
								fullWidth
								variant="outlined"
								className="settings-item-btn"
							>
								<i className="bi bi-buildings text-3xl" />
								<span className="ml-2">{t("settings.company")}</span>
							</Button>
						</Link>
					</Grid>
				)}

				{hasPermission("SETTINGS_STAFF") && (
					<Grid item={true} lg={4} sm={6} xs={12}>
						<Link to="/admin/settings/staff" className="settings-item">
							<Button
								fullWidth
								variant="outlined"
								className="settings-item-btn"
							>
								<i className="bi bi-people text-3xl" />
								<span className="ml-2">{t("settings.staffSettings")}</span>
							</Button>
						</Link>
					</Grid>
				)}

				{hasPermission("SETTINGS_PERMISSIONS") && (
					<Grid item={true} lg={4} sm={6} xs={12}>
						<Link to="/admin/settings/permission" className="settings-item">
							<Button
								fullWidth
								variant="outlined"
								className="settings-item-btn"
							>
								<i className="bi bi-person-lock text-3xl" />
								<span className="ml-2">{t("settings.permissionSettings")}</span>
							</Button>
						</Link>
					</Grid>
				)}

				{hasPermission("SETTINGS_VALUTE") && (
					<Grid item={true} lg={4} sm={6} xs={12}>
						<Link to="/admin/settings/currency" className="settings-item">
							<Button
								fullWidth
								variant="outlined"
								className="settings-item-btn"
							>
								<i className="bi bi-coin text-3xl" />
								<span className="ml-2">{t("settings.currencySettings")}</span>
							</Button>
						</Link>
					</Grid>
				)}

				{hasPermission("SETTINGS_HOMES") && (
					<Grid item={true} lg={4} sm={6} xs={12}>
						<Link to="/admin/settings/home" className="settings-item">
							<Button
								fullWidth
								variant="outlined"
								className="settings-item-btn"
							>
								<i className="bi bi-building-gear text-3xl" />
								<span className="ml-2">{t("settings.homeSettings")}</span>
							</Button>
						</Link>
					</Grid>
				)}
			</Grid>
		</div>
	)
}

export default Settings
