import {
	Route,
	createBrowserRouter,
	createRoutesFromElements,
	Outlet
} from "react-router-dom"

import NotFoundPage from "components/ui/NotFoundPage"

import BaseLayout from "layouts/BaseLayout"

import LogInSignUpLayout from "layouts/LogInSignUpLayout"
import LogIn from "pages/login-signup/LogIn"
import Arrears from "pages/admin/arrears/Arrears"
import Clients from "pages/admin/clients/Clients"
import Contract from "pages/admin/contract/Contract"
import Order from "pages/admin/order/Order"
import Payment from "pages/admin/payment/Payment"
import Settings from "pages/admin/settings/Settings"
import Companies from "pages/admin/settings/company/Companies"
import CompanyAddEdit from "pages/admin/settings/company/CompanyAddEdit"
import Staff from "pages/admin/settings/staff/Staff"
import Block from "pages/admin/block/Block"
import AuthProvider from "context/providers/AuthProvider"
import Objects from "pages/admin/objects/Objects"
import ObjectAddEdit from "pages/admin/objects/ObjectAddEdit"
import ShaxmatkaBlock from "pages/admin/shaxmatka/ShaxmatkaBlock"
import ContractAdd from "pages/admin/contract/ContractAdd"
import ClientAddEdit from "pages/admin/clients/ClientAddEdit"
import ContractView from "pages/admin/contract/ContractView"
import PaymentAdd from "pages/admin/payment/PaymentAdd"
import Changes from "pages/admin/changes/Changes"
import Home from "pages/admin/settings/home/Home"
import ClientView from "pages/admin/clients/ClientView"
import Profile from "pages/profile/Profile"
import PaymentChanges from "pages/admin/payment-changes/PaymentChanges"
import Homes from "pages/homes/Homes"
import Currency from "pages/admin/settings/currency/Currency"
import BaseProvider from "context/BaseProvider"
import Permission from "pages/admin/settings/permission/Permission"
import OrderView from "pages/admin/order/OrderView"
import CRM from "pages/admin/crm/CRM"
import CRMCardAddEdit from "pages/admin/crm/crm-parts/card/card-add-edit/CRMCardAddEdit"
import Tasks from "pages/admin/tasks/Tasks"
import RequireAuth from "hoc/RequireAuth"
import RequireChildAuth from "hoc/RequireChildAuth"
import Dashboard from "pages/admin/dashboard/Dashboard"
import ContractAddFromShaxmatka from "pages/admin/contract/ContractAddFromShaxmatka"
import BossLayout from "layouts/BossLayout"
import { default as BossDashboard } from "pages/boss/dashboard/Dashboard"
import { default as BossPayment } from "pages/boss/payment/Payment"
import { default as BossArrears } from "pages/boss/arrears/Arrears"
import { default as BossResidentialComplex } from "pages/boss/residential-complex/ResidentialComplex"
import { default as BossContract } from "pages/boss/contract/Contract"
import { default as BossCRM } from "pages/boss/crm/CRM"
import { default as BossSale } from "pages/boss/sale/Sale"

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<Outlet />}>
			<Route element={<AuthProvider />}>
				<Route element={<LogInSignUpLayout />}>
					<Route path="login" element={<LogIn />} />
				</Route>
				<Route element={<BaseProvider />}>
					<Route element={<Outlet />} path="admin">
						<Route element={<BaseLayout />}>
							<Route
								path="dashboard"
								element={
									<RequireAuth permissionCode="MAINPAGE">
										<Dashboard />
									</RequireAuth>
								}
							/>

							{/* <Route
								path="boss"
								element={
									<RequireAuth permissionCode="MAINPAGE">
										<Boss />
									</RequireAuth>
								}
							/> */}

							<Route
								path="homes"
								element={
									<RequireAuth permissionCode="HOMES">
										<Homes />
									</RequireAuth>
								}
							/>

							<Route
								path="order"
								element={
									<RequireAuth permissionCode="ORDER">
										<Outlet />
									</RequireAuth>
								}
							>
								<Route index element={<Order />} />
								<Route path="view/:id" element={<OrderView />} />
							</Route>

							<Route
								path="object"
								element={
									<RequireAuth permissionCode="OBJECTS">
										<Outlet />
									</RequireAuth>
								}
							>
								<Route index element={<Objects />} />
								<Route
									path="add"
									element={
										<RequireChildAuth permissionCode="ADD_OBJECTS">
											<ObjectAddEdit />
										</RequireChildAuth>
									}
								/>
							</Route>

							<Route
								path="shaxmatka"
								element={
									<RequireAuth permissionCode="SHOWROOM">
										<Outlet />
									</RequireAuth>
								}
							>
								<Route index element={<ShaxmatkaBlock />} />
							</Route>

							<Route
								path="contract"
								element={
									<RequireAuth permissionCode="CONTRACTS">
										<Outlet />
									</RequireAuth>
								}
							>
								<Route index element={<Contract />} />
								<Route
									path="add"
									element={
										<RequireChildAuth permissionCode="ADD_CONTRACTS">
											<ContractAdd />
										</RequireChildAuth>
									}
								/>
								<Route
									path="add-from-shaxmatka"
									element={
										<RequireChildAuth permissionCode="ADD_CONTRACTS">
											<ContractAddFromShaxmatka />
										</RequireChildAuth>
									}
								/>
								<Route path="view/:id" element={<ContractView />} />
							</Route>

							<Route
								path="payment"
								element={
									<RequireAuth permissionCode="PAYMENTS">
										<Outlet />
									</RequireAuth>
								}
							>
								<Route index element={<Payment />} />
								<Route path="add" element={<PaymentAdd />} />
							</Route>

							<Route
								path="client"
								element={
									<RequireAuth permissionCode="CUSTOMS">
										<Outlet />
									</RequireAuth>
								}
							>
								<Route index element={<Clients />} />
								<Route
									path="add"
									element={
										<RequireChildAuth permissionCode="ADD_CUSTOMS">
											<ClientAddEdit />
										</RequireChildAuth>
									}
									client
								/>
								<Route path="view/:id" element={<ClientView />} />
							</Route>

							<Route
								path="arrears"
								element={
									<RequireAuth permissionCode="DEBITORS">
										<Arrears />
									</RequireAuth>
								}
							/>

							<Route
								path="change"
								element={
									<RequireAuth permissionCode="CHANGES">
										<Changes />
									</RequireAuth>
								}
							/>

							<Route
								path="payment-change"
								element={
									<RequireAuth permissionCode="PAYMENTS_CHANGES">
										<PaymentChanges />
									</RequireAuth>
								}
							/>

							<Route
								path="tasks"
								element={
									<RequireAuth permissionCode="TASKS">
										<Tasks />
									</RequireAuth>
								}
							/>

							<Route
								path="crm"
								element={
									<RequireAuth permissionCode="CRM">
										<Outlet />
									</RequireAuth>
								}
							>
								<Route index element={<CRM />} />
								<Route path="lead/add" element={<CRMCardAddEdit />} />
								<Route path="lead/edit/:id" element={<CRMCardAddEdit />} />
							</Route>

							<Route
								path="block"
								element={
									<RequireAuth permissionCode="OBJECTS">
										<Block />
									</RequireAuth>
								}
							/>

							<Route
								path="settings"
								element={
									<RequireAuth permissionCode="SETTINGS">
										<Outlet />
									</RequireAuth>
								}
							>
								<Route index element={<Settings />} />
								<Route
									path="home"
									element={
										<RequireChildAuth permissionCode="SETTINGS_HOMES">
											<Home />
										</RequireChildAuth>
									}
								/>
								<Route
									path="company"
									element={
										<RequireChildAuth permissionCode="SETTINGS_COMPANY">
											<Outlet />
										</RequireChildAuth>
									}
								>
									<Route index element={<Companies />} />
									<Route path="add" element={<CompanyAddEdit />} />
									<Route path="edit/:id" element={<CompanyAddEdit />} />
								</Route>
								<Route
									path="staff"
									element={
										<RequireChildAuth permissionCode="SETTINGS_STAFF">
											<Staff />
										</RequireChildAuth>
									}
								/>
								<Route
									path="currency"
									element={
										<RequireChildAuth permissionCode="SETTINGS_VALUTE">
											<Currency />
										</RequireChildAuth>
									}
								/>
								<Route
									path="permission"
									element={
										<RequireChildAuth permissionCode="SETTINGS_PERMISSIONS">
											<Permission />
										</RequireChildAuth>
									}
								/>
							</Route>

							<Route path="profile" element={<Profile />} />

							<Route path="not-found" element={<NotFoundPage />} />
						</Route>
					</Route>
					<Route
						element={
							<RequireAuth
								permissionCode="STATISTICS"
								hasChildPermission={false}
							>
								<Outlet />
							</RequireAuth>
						}
						path="boss"
					>
						<Route element={<BossLayout />}>
							<Route path="dashboard" element={<BossDashboard />} />
							<Route path="payment" element={<BossPayment />} />
							<Route path="arrears" element={<BossArrears />} />
							<Route
								path="residential-complex"
								element={<BossResidentialComplex />}
							/>
							<Route path="contract" element={<BossContract />} />
							<Route path="crm" element={<BossCRM />} />
							<Route path="sale" element={<BossSale />} />
							<Route path="not-found" element={<NotFoundPage />} />
						</Route>
					</Route>
					<Route path="*" element={<NotFoundPage />} />
				</Route>
			</Route>
		</Route>
	)
)

export default router
