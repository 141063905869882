import useCurrency from "hooks/useCurrency"
import moment from "moment"
import { NumericFormat } from "react-number-format"

const DailyCurrency = () => {
	const { currencyData } = useCurrency()

	return (
		<div className="daily-currency-wrapper">
			<div className="daily-currency-title">
				Valyuta {moment(currencyData?.date).format("DD/MM/YYYY")}
			</div>
			<NumericFormat
				value={currencyData?.sum}
				displayType={"text"}
				allowNegative={false}
				thousandSeparator={" "}
				decimalScale={2}
				className="bg-transparent"
				suffix={" UZS"}
			/>
		</div>
	)
}
export default DailyCurrency
