import {
	ButtonBase,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow
} from "@mui/material"
import CurrencyFormat from "components/ui/text-formats/CurrencyFormat"
import { Trans, useTranslation } from "react-i18next"
import { NumericFormat } from "react-number-format"
import {
	homeTypeVariants,
	repairTypeVariants,
	residentTypeVariants
} from "shared/tableColVariantsList"

const { forwardRef } = require("react")

const ShaxmatkaHomeDetailToPrint = forwardRef((props, ref) => {
	const { t } = useTranslation()

	const setHomeType = (item, typeArr) => {
		let result = typeArr.filter((variant) => variant.code === item)
		if (result.length > 0) {
			return t(result[0].label)
		}
		return ""
	}

	return (
		<div ref={ref} className="home-detail-to-print-wrapper">
			<div className="row-data">
				<div className="row-data-item is-start">
					<div className="helper-text">
						{t("shaxmatka.homeDetail.residentialComplex")}
					</div>
					<div>{props?.objectData?.name}</div>
				</div>
				<div>
					<img
						className="w-[40px]"
						src={require("assets/images/logo/logo-mini.png")}
						alt="logo"
					/>
				</div>
				<div className="row-data-item is-end">
					<div className="helper-text">
						{t("shaxmatka.homeDetail.callCenter")}
					</div>
					<div>+998 99 111-11-11</div>
				</div>
			</div>
			<div className="divider"></div>
			<div className="row-data">
				<div className="row-data-item is-start">
					<div className="helper-text">{t("shaxmatka.homeDetail.areaAll")}</div>
					<div>
						<NumericFormat
							value={props?.homeData?.square}
							allowNegative={false}
							displayType={"text"}
							decimalScale={2}
							className="w-full text-right"
						/>{" "}
						<Trans i18nKey="common.global.meter">
							m<sup>2</sup>
						</Trans>
					</div>
				</div>
				<div className="row-data-item is-center">
					<div className="helper-text">{t("shaxmatka.homeDetail.rooms")}</div>
					<div className="row-data-item is-center">
						<ButtonBase className="home-rooms">
							{props?.homeData?.rooms}
						</ButtonBase>
					</div>
				</div>
				<div className="row-data-item is-end">
					<div className="helper-text text-center">
						{t("shaxmatka.homeDetail.price")}
					</div>
					<div>
						<NumericFormat
							value={props?.homeData?.sellPrice}
							allowNegative={false}
							displayType={"text"}
							thousandSeparator={" "}
							decimalScale={1}
							className="w-full text-center"
							suffix={props?.homeData?.isvalute === "1" ? " $" : " UZS"}
						/>
					</div>
				</div>
			</div>
			<div className="divider"></div>
			<div className="row-data">
				<div className="row-data-item">
					<div className="helper-text">
						{t("shaxmatka.homeDetail.buildingName")}
					</div>
					<div>{props?.blockName}</div>
				</div>
				<div className="row-data-item">
					<div className="helper-text">
						{t("shaxmatka.homeDetail.residentType")}
					</div>
					<div>
						{props?.homeData?.islive
							? setHomeType(props?.homeData.islive, residentTypeVariants)
							: "—"}
					</div>
				</div>
				<div className="row-data-item">
					<div className="helper-text">{t("shaxmatka.homeDetail.stage")}</div>
					<div>{props?.homeData?.stage || "—"}</div>
				</div>
				<div className="row-data-item">
					<div className="helper-text">
						{t("shaxmatka.homeDetail.houseNumber")}
					</div>
					<div>{props?.homeData?.number || "—"}</div>
				</div>
				<div className="row-data-item">
					<div className="helper-text">
						{t("shaxmatka.homeDetail.repairType")}
					</div>
					<div>
						{props?.homeData?.isrepaired
							? setHomeType(props?.homeData.isrepaired, repairTypeVariants)
							: "—"}
					</div>
				</div>
				<div className="row-data-item">
					<div className="helper-text">
						{t("shaxmatka.homeDetail.position")}
					</div>
					<div>
						{props?.homeData?.status
							? setHomeType(props?.homeData?.status, homeTypeVariants)
							: ""}
					</div>
				</div>
			</div>
			{/* Note! First approach */}
			{/* <div className="my-auto">
				<div className="row-data mt-4">
					<div className="row-data-item w-1/2">
						<div className="helper-text text-center leading-3">
							{t("shaxmatka.homeDetail.plan3D")}
						</div>
						<div className="h-full">
							<img
								className="px-4 h-full"
								src={`${process.env.REACT_APP_BACKEND_URL}/${props.homeData?.plan?.link}`}
								alt={props.homeData?.plan?.name}
							/>
						</div>
					</div>
					<div className="row-data-item w-1/2">
						<div className="helper-text text-center leading-3">
							{t("shaxmatka.homeDetail.plan2D")}
						</div>
						<div className="h-full">
							<img
								className="px-4 h-full"
								src={`${process.env.REACT_APP_BACKEND_URL}/${props.homeData?.plan?.link2d}`}
								alt={props.homeData?.plan?.name}
							/>
						</div>
					</div>
				</div>
			</div> */}
			<div className="h-[calc(100vh-400px)] flex flex-col items-center mt-4">
				<div className="row-data-item h-1/2 py-5">
					<div className="helper-text text-center mb-2">
						{t("shaxmatka.homeDetail.plan3D")}
					</div>
					<img
						className="h-full"
						src={`${process.env.REACT_APP_BACKEND_URL}/${props?.homeData?.plan?.link}`}
						alt={props?.homeData?.plan?.name}
					/>
				</div>
				<div className="row-data-item h-1/2 py-5">
					<div className="helper-text text-center mb-2">
						{t("shaxmatka.homeDetail.plan2D")}
					</div>
					<img
						className="h-full"
						src={`${process.env.REACT_APP_BACKEND_URL}/${props?.homeData?.plan?.link2d}`}
						alt={props?.homeData?.plan?.name}
					/>
				</div>
			</div>
			<div className="mt-6">
				<TableContainer className="flex-auto h-full">
					<Table>
						<TableHead>
							<TableRow>
								<TableCell>
									<span className="!text-[10px] whitespace-nowrap text-gray-500">
										{t("shaxmatka.homeDetail.startPrice")}
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[10px] whitespace-nowrap text-gray-500">
										{t("shaxmatka.homeDetail.percentage")}
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[10px] whitespace-nowrap text-gray-500">
										{t("shaxmatka.homeDetail.discount")}
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[10px] whitespace-nowrap text-gray-500">
										{t("shaxmatka.homeDetail.sellPrice")}
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[10px] whitespace-nowrap text-gray-500">
										{t("shaxmatka.homeDetail.month")}
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[10px] whitespace-nowrap text-gray-500">
										{t("shaxmatka.homeDetail.monthlyPay")}
									</span>
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody className="overflow-hidden">
							<TableRow>
								<TableCell>
									<span className="!text-[12px] whitespace-nowrap">
										{props?.homeData?.startPrice ? (
											<CurrencyFormat
												value={props?.homeData?.startPrice}
												suffix={
													props?.homeData?.isvalute === "1" ? " $" : " UZS"
												}
												decimalScale={1}
											/>
										) : (
											0
										)}
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[12px] whitespace-nowrap">
										<NumericFormat
											value={props?.homeData.startPricePercentage}
											allowNegative={false}
											displayType={"text"}
											thousandSeparator={" "}
											decimalScale={2}
											className="w-full text-center"
											suffix=" %"
										/>
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[12px] whitespace-nowrap">
										{props?.homeData?.discount ? (
											<CurrencyFormat
												value={props?.homeData?.discount}
												suffix={
													props?.homeData?.isvalute === "1" ? " $" : " UZS"
												}
												decimalScale={1}
											/>
										) : (
											0
										)}
										(
										<NumericFormat
											value={props?.homeData?.discountPercentage}
											allowNegative={false}
											displayType={"text"}
											thousandSeparator={" "}
											decimalScale={2}
											className="w-full text-center"
											suffix=" %"
										/>
										)
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[12px] whitespace-nowrap">
										<NumericFormat
											value={props?.homeData?.sellPrice}
											allowNegative={false}
											displayType={"text"}
											thousandSeparator={" "}
											decimalScale={1}
											className="w-full text-center"
											suffix={props?.homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[12px] whitespace-nowrap">
										{props?.homeData?.month}
									</span>
								</TableCell>
								<TableCell>
									<span className="!text-[12px] whitespace-nowrap">
										<NumericFormat
											value={props?.homeData?.priceForMonth}
											allowNegative={false}
											displayType={"text"}
											thousandSeparator={" "}
											decimalScale={1}
											className="w-full text-center"
											suffix={props?.homeData?.isvalute === "1" ? " $" : " UZS"}
										/>
									</span>
								</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</TableContainer>
			</div>
			<div className="divider mt-auto mb-3"></div>
		</div>
	)
})

export default ShaxmatkaHomeDetailToPrint
