import moment from "moment"
import { useTranslation } from "react-i18next"
import CRMCallAudioMessage from "./message-types/CRMCallAudioMessage"
import CRMTaskMessage from "./message-types/CRMTaskMessage"
import { Badge, Button, CircularProgress, TextField } from "@mui/material"
import { Fragment, useState } from "react"
import CommentDatePicker from "./comment-box-items/CommentDatePicker"
import CommentTypeSelect from "./comment-box-items/CommentTypeSelect"
import CommentStaffSelect from "./comment-box-items/CommentStaffSelect"
import useAuth from "hooks/useAuth"
import useAxiosPrivate from "hooks/useAxiosPrivate"
import { useParams } from "react-router-dom"
import useNotification from "hooks/useNotification"
import CRMLeadCommentMessage from "./message-types/CRMLeadCommentMessage"
import CRMTasksModal from "./CRMTasksModal"
import CRMDataChangeMessage from "./message-types/CRMDataChangeMessage"
import usePermission from "hooks/usePermission"

const CRMCardLeadHistory = ({
	history = {},
	tasks = [],
	updateFn = () => {},
	isUserNew = false
}) => {
	const { t } = useTranslation()
	const { id } = useParams()
	const [{ user }] = useAuth()
	const [isSubmitting, setIsSubmitting] = useState(false)
	const [openTasks, setOpenTasks] = useState(false)
	const axiosPrivate = useAxiosPrivate()
	const sendNotification = useNotification()
	const [selectedType, setSelectedType] = useState({})
	const [selectedStaff, setSelectedStaff] = useState(user?.user)
	const [commentDate, setCommentDate] = useState(null)
	const [commentText, setCommentText] = useState("")
	const { hasPermission } = usePermission()

	const sendComment = async () => {
		if (
			selectedType?.code &&
			selectedStaff?.id &&
			commentText &&
			(selectedType?.code === "TASK" ? moment(commentDate).isValid() : true)
		) {
			try {
				setIsSubmitting(true)
				const response = await axiosPrivate.post(
					selectedType?.code === "TASK" ? "crm/task" : "crm/lead-comment",
					selectedType?.code === "TASK"
						? JSON.stringify({
								lead_id: id,
								user_id: selectedStaff?.id,
								title: commentText,
								date: moment(commentDate).format("YYYY-MM-DD HH:mm")
						  })
						: JSON.stringify({
								lead_id: id,
								user_id: selectedStaff?.id,
								comment: commentText
						  }),
					{
						headers: { "Content-Type": "application/json" }
					}
				)
				if (response.data && response.data.status) {
					updateFn()
					setCommentText("")
					setCommentDate(null)
				}
				setIsSubmitting(false)
			} catch (error) {
				setIsSubmitting(false)
				sendNotification({
					msg: error?.response?.data?.message || error?.message,
					variant: "error"
				})
			}
		} else {
			sendNotification({
				msg:
					selectedType?.code === "TASK"
						? "Iltimos, topshiriqni yaratish uchun kerakli maydonlarni to'ldiring!"
						: "Iltimos, xabarnomani yaratish uchun kerakli maydonlarni to'ldiring!",
				variant: "warning"
			})
		}
	}

	const cancelSend = () => {
		setCommentText("")
	}

	return (
		<div className="lead-history-items-wrapper">
			<div
				className={`lead-history-details-wrapper${isUserNew ? " is-new" : ""}`}
				id="lead-history-details-wrapper"
			>
				{history && Object.keys(history).length > 0 ? (
					Object.keys(history).map((historyItem, historyIndex) => (
						<div
							className="date-history-wrapper"
							key={`date-history-${historyIndex}`}
						>
							<div className="date-time-title">
								{moment(historyItem, "DD_MM_YYYY").format("DD.MM.YYYY")}
							</div>
							<div className="date-items-wrapper">
								{history[historyItem] &&
									history[historyItem].length > 0 &&
									history[historyItem].map((item, itemIndex) => (
										<div
											className="item-details-wrapper"
											key={`item-details-${itemIndex}`}
										>
											{item.type === "call_history" && (
												<CRMCallAudioMessage data={item} />
											)}
											{item.type === "task" && <CRMTaskMessage data={item} />}
											{item.type === "lead_comment" && (
												<CRMLeadCommentMessage data={item} />
											)}
											{item.type === "lead_history" && (
												<CRMDataChangeMessage data={item} />
											)}
										</div>
									))}
							</div>
						</div>
					))
				) : (
					<div className="no-data-placeholder">
						<span className="no-data-found-wrapper bg-transparent !text-base-color-light p-4 rounded-lg">
							<i className="bi bi-exclamation-octagon text-xl leading-4 mr-1" />{" "}
							{t("common.global.noDataFound")}
						</span>
					</div>
				)}
			</div>
			{!isUserNew &&
				(hasPermission("CRM_ADD_TASK") || hasPermission("CRM_ADD_COMMENT")) && (
					<div className="lead-history-input-wrapper">
						<div className="input-body">
							<div className="flex items-center justify-between">
								<div className="input-top-actions-wrapper">
									<CommentTypeSelect
										selectedType={selectedType}
										setSelectedType={setSelectedType}
									/>
									<div className="divider"></div>
									{selectedType?.code === "TASK" && (
										<Fragment>
											<CommentDatePicker
												commentDate={commentDate}
												setCommentDate={setCommentDate}
											/>
											<div className="divider"></div>
										</Fragment>
									)}

									<CommentStaffSelect
										selectedStaff={selectedStaff}
										setSelectedStaff={setSelectedStaff}
									/>
								</div>

								{tasks && Object.keys(tasks).length > 0 && (
									<Badge badgeContent={Object.keys(tasks).length} color="error">
										<Button variant="action" onClick={() => setOpenTasks(true)}>
											<i className="bi bi-journal-bookmark" />
										</Button>
									</Badge>
								)}
							</div>

							<div className="input-text-area">
								<TextField
									color="formColor"
									variant="outlined"
									fullWidth
									id="comment-text-area-field"
									name="comment-text-area-field"
									className="comment-text-area-field"
									label=""
									multiline={true}
									rows={3}
									value={commentText}
									onChange={(event) => {
										setCommentText(event.target.value)
									}}
									autoComplete="off"
								/>
								{!commentText && (
									<span className="comment-text-area-field-placeholder">
										Matnni kiriting...
									</span>
								)}
							</div>
							<div className="input-action-buttons-wrapper">
								<Button
									color="success"
									variant="contained"
									size="small"
									className="!mr-2"
									onClick={() => sendComment()}
									disabled={isSubmitting}
								>
									{isSubmitting && (
										<CircularProgress
											size={15}
											color="inherit"
											className="mr-1"
										/>
									)}
									Yuborish
								</Button>
								<Button
									variant="contained"
									color="inherit"
									size="small"
									onClick={() => cancelSend()}
									disabled={isSubmitting}
								>
									{t("common.button.cancel")}
								</Button>
							</div>
						</div>
					</div>
				)}

			{openTasks && (
				<CRMTasksModal open={openTasks} setOpen={setOpenTasks} tasks={tasks} />
			)}
		</div>
	)
}
export default CRMCardLeadHistory
